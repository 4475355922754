'use client';

import Link from 'next/link';
import Logo from '../logo/Logo';
import Wrapper from '../../components/wrapper/Wrapper';
import styles from './Footer.module.scss';
import navArr from '@/src/assets/staticObjects/navArr';
import { usePathname } from 'next/navigation';
import classNames from 'classnames';
import FooterLinks from '../../components/footerLinks/FooterLinks';

const Footer = () => {
  const pathname = usePathname().replace(/\//g, '');

  return (
    <footer className={styles.footer}>
      <FooterLinks />
      <div className={styles.footer__bottom}>
        <Wrapper className={styles.wrapper}>
          <div className={styles.footer__branding}>
            <Logo isFooter={true} className={styles.logo} />
            <p>
              A part of{' '}
              <Link href="https://ashstonestudios.com/" target="_blank" rel="noopener noreferrer">
                Ashstone Studios
              </Link>
            </p>
          </div>

          <nav className={styles.nav}>
            <ul className={styles.nav__list}>
              {navArr.map((item, i) => {
                const pageClass = classNames(styles.nav__item, {
                  [styles.currPage]: pathname === item.navHref,
                });

                return (
                  <li className={`${styles.nav__item} ${pageClass}`} key={`${item.navName}-${i}`}>
                    <Link href={`/${item.navHref}`}>{item.navName}</Link>
                  </li>
                );
              })}
            </ul>
          </nav>
          <div className={styles.info}>
            <div className={styles.info__links}>
              <Link href="/help-center/privacy-policy/">Privacy Policy</Link>
            </div>
            <p className={styles.copyright}>Copyright © All Rights Reserved</p>
          </div>
        </Wrapper>
      </div>
    </footer>
  );
};

export default Footer;
