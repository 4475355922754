'use client';

import Link from 'next/link';
import { FC, ReactNode, RefObject } from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';

type TButton = {
  children: ReactNode;
  color?: 'primary-light' | 'primary-dark' | 'secondary-light' | 'secondary-dark' | 'clear';
  action: 'interactive' | 'standard';
  buttonUrl?: string;
  onClick?: () => void;
  ariaLabel?: string;
  ariaLabelledby?: string;
  target?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  formBtn?: boolean;
  isLoading?: boolean;
};

const Button: FC<TButton> = ({
  color,
  onClick,
  buttonUrl,
  children,
  ariaLabel,
  target,
  className,
  formBtn,
  type,
  isLoading,
  action,
}) => {
  const btnColor = styles[`${color}`];
  const btnFullClassName = classNames(styles.btn, btnColor, className, {
    [styles.formBtn]: formBtn,
    [styles.animated]: action === 'interactive',
    [styles.standard]: action === 'standard',
  });

  return (
    <>
      {buttonUrl ? (
        <Link
          href={buttonUrl}
          className={btnFullClassName}
          aria-label={ariaLabel}
          target={target ? `_blank` : undefined}
          rel={target ? 'noopener noreferrer' : undefined}>
          {action == 'interactive' ? (
            <div className={styles.animated__container}>
              <div className={styles.animated__wrapper}>
                <div className={`${styles.arrow} ${styles.arrow__left}`}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8 6L12 10L8 14"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <span className={styles.animated__text}>{children}</span>
                <div className={`${styles.arrow} ${styles.arrow__right}`}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8 6L12 10L8 14"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          ) : (
            <>{children}</>
          )}
        </Link>
      ) : (
        <button
          type={type ?? 'button'}
          className={classNames(styles.btn, btnColor, className, {
            [styles.formBtn]: formBtn,
            [styles[`formBtn--isLoading`]]: isLoading,
            [styles.animated]: action === 'interactive',
            [styles.standard]: action === 'standard',
          })}
          onClick={onClick}
          aria-label={ariaLabel}>
          {action == 'interactive' ? (
            <div className={styles.animated__container}>
              <div className={styles.animated__wrapper}>
                <div className={`${styles.arrow} ${styles.arrow__left}`}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8 6L12 10L8 14"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <span className={styles.animated__text}>{children}</span>
                <div className={`${styles.arrow} ${styles.arrow__right}`}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8 6L12 10L8 14"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          ) : (
            children
          )}
        </button>
      )}
    </>
  );
};

export default Button;
