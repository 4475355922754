const navArr = [
  {
    navName: 'Themes',
    navHref: 'themes',
  },
  {
    navName: 'Contacts',
    navHref: 'contacts',
  },
  {
    navName: 'Help Center',
    navHref: 'help-center',
  },
];

export default navArr;
