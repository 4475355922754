'use client';

import React, { FC, useEffect, useState } from 'react';
import Link from 'next/link';
import styles from './Nav.module.scss';
import { usePathname } from 'next/navigation';
import classNames from 'classnames';
import navArr from '@/src/assets/staticObjects/navArr';
import Button from '../../components/button/Button';
import Logo from '../logo/Logo';
import Wrapper from '../../components/wrapper/Wrapper';
import { useMatchMedia } from '@/src/assets/hooks/useMatchMedia';

type TNav = {
  isNavActive: boolean;
  setIsNavActive: (isActive: boolean) => void;
  isHeaderDark: boolean;
};

const Nav: FC<TNav> = ({ isNavActive, setIsNavActive, isHeaderDark }) => {
  const [scrolled, setScrolled] = useState(0);
  const pathname = usePathname().replace(/\//g, '');

  const navStyle = classNames(styles.nav, {
    [styles.active]: isNavActive,
    [styles.dark__mode]: isHeaderDark,
  });

  const { isNarrowScreen } = useMatchMedia();

  useEffect(() => {
    const scrolledNow = window.scrollY;
    if (isNarrowScreen && isNavActive) {
      document.body.style.cssText = `overflow-y: hidden; position: fixed; height: 100vh; top: -${scrolledNow}px`;
      setScrolled(scrolledNow);
    } else {
      document.body.style.cssText = `overflow-y: initial; position: initial; height: auto;`;
      window.scrollBy(0, scrolled);
      setScrolled(0);
    }

    return () => {
      document.body.style.cssText = `overflow-y: initial; position: initial; height: auto; top: -${scrolledNow}px`;
    };
  }, [isNavActive]);

  return (
    <nav className={`${styles.nav} ${navStyle}`}>
      <div className={styles.panel}>
        <Wrapper className={styles.wrapper}>
          <Logo />
          <button
            className={styles.close__button}
            onClick={() => setIsNavActive(false)}
            aria-label="Close">
            <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.929 10.929L25.0711 25.0711" strokeWidth="2" strokeLinecap="round" />
              <path d="M25.071 10.929L10.9289 25.0711" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </button>
        </Wrapper>
      </div>
      <div className={styles.nav__container}>
        <ul className={styles.nav__list}>
          {navArr.map((item, i) => {
            const pageClass = classNames(styles.nav__item, {
              [styles.currPage]: pathname === item.navHref,
            });

            return (
              <li
                className={pageClass}
                onClick={() => setIsNavActive(false)}
                key={`${item.navName}-${i}`}>
                <Link href={`/${item.navHref}`}>{item.navName}</Link>
              </li>
            );
          })}
        </ul>
        {isNarrowScreen && (
          <Button
            action="interactive"
            ariaLabel="Discover Themes"
            buttonUrl="/themes"
            className={styles.button}
            color={isHeaderDark ? 'primary-light' : 'primary-dark'}>
            Discover Themes
          </Button>
        )}
      </div>
    </nav>
  );
};

export default Nav;
