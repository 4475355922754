import { ReactNode, FC } from 'react';
import styles from './Wrapper.module.scss';

type TWrapper = {
  className?: string | null;
  children: ReactNode;
};

const Wrapper: FC<TWrapper> = ({ children, className }) => {
  return <div className={`${styles.wrapper} ${className && className}`}>{children}</div>;
};

export default Wrapper;
