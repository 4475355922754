import React from 'react';
import styles from './FooterLinks.module.scss';
import NextImage from '../nextImage/NextImage';
import logo_noord from '@/public/images/collab/logo_noord.svg';
import logo_ac from '@/public/images/collab/logo_ac.svg';
import logo_segment from '@/public/images/collab/logo_segment.svg';
import arrow from '@/public/images/rounded_arrow.svg';
import Link from 'next/link';
import Wrapper from '../wrapper/Wrapper';

const FooterLinks = () => {
  return (
    <div className={styles.footerLinksWrapper}>
      <Wrapper className={styles.wrapper}>
        <div className={styles.blocks}>
          <Link
            href="https://madebysegment.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.block}>
            <div className={styles.block__logo}>
              <NextImage
                src={logo_segment}
                alt="Logo Segment"
                fill
                sizes="(max-width: 576px) 99.8px, 139px"
              />
            </div>
            <p className={styles.block__descr}>High-end Shopify themes with advanced feature sets.</p>
          </Link>
          <Link
            href="https://apparentcollective.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.block}>
            <div className={styles.block__logo}>
              <NextImage
                src={logo_ac}
                alt="Logo Apparent Collective"
                fill
                sizes="(max-width: 576px) 92.6px, 129px"
              />
            </div>
            <p className={styles.block__descr}>Premium Shopify themes with broader functionality.</p>
          </Link>
          <div className={styles.block}>
            <div className={styles.block__logo}>
              <NextImage
                src={logo_noord}
                alt="Logo Noord"
                fill
                sizes="(max-width: 576px) 90px, 125px"
              />
            </div>
            <p className={styles.block__descr}>
              Budget-friendly Shopify themes with all primary features for your store.
            </p>

            <div className={styles.marker}>
              <p>You are Here</p>
              <div className={styles.arrow}>
                <NextImage src={arrow} alt="Arrow" fill sizes="32px" />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default FooterLinks;
