'use client';

import { useState, useRef, ReactNode, FC, RefObject, useEffect } from 'react';
import { useParams, usePathname } from 'next/navigation';
import styles from './StickyHeader.module.scss';

interface INav {
  children: ReactNode;
}

const StickyHeader: FC<INav> = ({ children }) => {
  const [isSticky, setIsSticky] = useState(true);
  const [prevScroll, setPrevScroll] = useState(0);

  const ref: RefObject<HTMLDivElement> = useRef(null);

  const headClassName = `${styles.sticky} ${isSticky ? null : styles.sticky__hide}`;

  const params = useParams();
  const pathName = usePathname();

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll: number = window.scrollY;

      const scrollDelta = currentScroll - prevScroll;
      const isScrollingDown = scrollDelta > 0;
      const isScrollingUp = scrollDelta < 0;

      if (isScrollingDown) {
        if (currentScroll <= 200) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }

      if (isScrollingUp) {
        setIsSticky(true);
      }

      setPrevScroll(currentScroll);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSticky, prevScroll]);

  return (
    <div
      className={headClassName}
      ref={ref}
      style={{
        position: pathName === `/help-center/documentation/${params.slug}/` ? 'static' : 'sticky',
      }}>
      {children}
    </div>
  );
};

export default StickyHeader;
