'use client';

import React, { FC, useContext, useEffect, useState } from 'react';
import Wrapper from '../../components/wrapper/Wrapper';
import Nav from '../nav/Nav';
import Logo from '../logo/Logo';
import styles from './Header.module.scss';
import classNames from 'classnames';
import { HeaderContext } from '../../providers/headerContextProvider/HeaderContextProvider';

const Header: FC = () => {
  const [isNavActive, setIsNavActive] = useState(false);
  const { isHeaderDark } = useContext(HeaderContext);

  return (
    <header
      className={classNames(styles.header, {
        [styles[`colored`]]: isHeaderDark,
      })}>
      <Wrapper className={styles.wrapper}>
        <Logo />
        <Nav
          isNavActive={isNavActive}
          setIsNavActive={setIsNavActive}
          isHeaderDark={isHeaderDark}
        />
        {!isNavActive && (
          <button
            className={styles.burger}
            onClick={() => setIsNavActive(true)}
            aria-label="Burger">
            <span className={styles.burger__line}></span>
            <span className={styles.burger__line}></span>
            <span className={styles.burger__line}></span>
          </button>
        )}
        {isNavActive && (
          <button
            className={styles.close__button}
            onClick={() => setIsNavActive(false)}
            aria-label="Close">
            <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.929 10.929L25.0711 25.0711" strokeWidth="2" strokeLinecap="round" />
              <path d="M25.071 10.929L10.9289 25.0711" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </button>
        )}
      </Wrapper>
    </header>
  );
};

export default Header;
