'use client';

import React, { ReactNode, FC, useState, useEffect } from 'react';
import Header from './header/Header';
import Footer from './footer/Footer';
import styles from './Layout.module.scss';
import StickyHeader from './stickyHeader/StickyHeader';
import classNames from 'classnames';

type Layout = {
  children: ReactNode;
  className?: string;
};

const Layout: FC<Layout> = ({ children, className }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const [scrollY, setScrollY] = useState(0);
  const [isShowBtn, setIsShowBtn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY;

      if (currentScroll <= 200) {
        setIsShowBtn(false);
      } else {
        setIsShowBtn(true);
      }

      setScrollY(currentScroll);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollY]);

  return (
    <>
      {/* TODO: IS .scroll-fix NEEDED? REMOVE IF NOT */}
      <div className="scroll-fix"></div>
      <div className={styles.layout}>
        <StickyHeader>
          <Header />
        </StickyHeader>
        <main className={classNames('main', className)}>{children}</main>
          <button onClick={scrollToTop} className={classNames(styles.scroll__button, {
            [styles['scroll__button--active']]: isShowBtn,
            [styles['scroll__button--hidden']]: !isShowBtn
          })}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M6 12L10 8L14 12"
                stroke="#0A163C"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
